import * as styles from "./large-section-header.module.css";
import React from "react";

function LargeSectionHeader({ children }) {
    return (
        <div className={styles.root}>
            <h1 className={styles.title}>
                {children}
            </h1>
        </div>
    );
}

export default LargeSectionHeader;