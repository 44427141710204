import BlogPostPreviewList from "../components/blog-post-preview-list";
import Container from "../components/container";
import LargeSectionHeader from "../components/large-section-header";
import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../containers/layout";
import React from "react";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import { mapEdgesToNodes } from "../lib/helpers";

import { responsiveTitle1 } from "../components/typography.module.css";

export const query = graphql`
  query NewsPageQuery {
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null }, postType: {title: {eq: "Article"}}, categories: {elemMatch: {title: {eq: "News"}}} }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`;

const NewsPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const postNodes = data && data.posts && mapEdgesToNodes(data.posts);

  return (
    <Layout>
      <SEO title="News" />
      <Container>
        <LargeSectionHeader>News</LargeSectionHeader>
        {postNodes && postNodes.length > 0 && (
            <BlogPostPreviewList
                nodes={postNodes}
                browseMoreHref="/archive/"
            />
        )}
      </Container>
    </Layout>
  );
};

export default NewsPage;
