// extracted by mini-css-extract-plugin
export const root = "blog-post-preview-module--root--2s4gO";
export const inList = "blog-post-preview-module--inList--2XJVE blog-post-preview-module--root--2s4gO";
export const inGrid = "blog-post-preview-module--inGrid--26gTD blog-post-preview-module--root--2s4gO";
export const leadMediaThumb = "blog-post-preview-module--leadMediaThumb--1LJqw";
export const text = "blog-post-preview-module--text--1g6NJ";
export const bodyPreview = "blog-post-preview-module--bodyPreview--2RMzu";
export const readMoreLabel = "blog-post-preview-module--readMoreLabel--eh7hc";
export const title = "blog-post-preview-module--title--1OPpe";
export const excerpt = "blog-post-preview-module--excerpt--3vwlB typography-module--base--2DtHq";
export const date = "blog-post-preview-module--date--1GxGN";